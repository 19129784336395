// Reusable Button Component
function Button({ children, onClick }) {
    return (
        <button
            onClick={onClick}
            className="bg-gradient-to-r from-accent to-yellow-500 text-white py-2 px-4 rounded-full shadow-md hover:scale-105 transform transition"
        >
            {children}
        </button>
    );
}

export default Button;
