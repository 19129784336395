// src/components/CreateProfile.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { auth } from '../firebase';
import { NOVELY_CORE_URL } from '../apiConfig'
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import Button from './Button';

function CreateProfile() {
    const [profileName, setProfileName] = useState('');
    const [profileAge, setProfileAge] = useState('');
    const [profileGender, setProfileGender] = useState('');
    const [profileLikes, setProfileLikes] = useState('');
    const { userId } = useContext(UserContext);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = await auth.currentUser.getIdToken();

            const requestData = {
                userId: userId,
                profileName: profileName,
                profileAge: profileAge,
                profileGender: profileGender,
                profileLikes: profileLikes.split(',').map((like) => like.trim()),
            };

            // Make the POST request to create a new profile
            await axios.post(
                `${NOVELY_CORE_URL}/profile`,
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Navigate back to the User page
            navigate('/user');
        } catch (error) {
            console.error('Error creating profile:', error);
            alert('Error creating profile. Please try again.');
        }
    };

    return (
        <div className="min-h-screen flex flex-col bg-gradient-to-br from-blue-100 to-purple-200">
            <NavBar />
            <main className="flex-grow">
                <div className="container mx-auto p-4">
                    {/* Brand Name */}
                    <div className="text-center mb-6">
                        <h1 className="text-4xl font-bold text-purple-600">Novely</h1>
                    </div>

                    <h2 className="text-3xl font-bold text-gray-700 text-center mb-6">
                        Create a New Profile
                    </h2>

                    <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-6">
                        {/* Profile Name */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">
                                Profile Name
                            </label>
                            <input
                                type="text"
                                value={profileName}
                                onChange={(e) => setProfileName(e.target.value)}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                        </div>

                        {/* Age */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">
                                Age
                            </label>
                            <input
                                type="number"
                                value={profileAge}
                                onChange={(e) => setProfileAge(e.target.value)}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                        </div>

                        {/* Gender */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">
                                Gender
                            </label>
                            <select
                                value={profileGender}
                                onChange={(e) => setProfileGender(e.target.value)}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                            >
                                <option value="">Select Gender</option>
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                                <option value="Non-binary">Non-binary</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        {/* Likes */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">
                                Likes (separated by commas)
                            </label>
                            <input
                                type="text"
                                value={profileLikes}
                                onChange={(e) => setProfileLikes(e.target.value)}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                                placeholder="e.g., Reading, Drawing, Music"
                            />
                        </div>

                        {/* Submit Button */}
                        <div className="flex justify-center">
                            <Button
                                type="submit"
                                className="w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 transition duration-200"
                            >
                                Create Profile
                            </Button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
}

export default CreateProfile;
