import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { UserContext } from '../contexts/UserContext';

function NavBar() {
  const navigate = useNavigate();
  const { setUserId } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Clear userId from context or local storage
      setUserId(null); // If using context
      localStorage.removeItem('userId'); // If using local storage
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <nav className="bg-gradient-to-r from-primary to-secondary p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-accent text-2xl font-bold font-cursive">
          Novely
        </Link>
        <div className="space-x-4">
          <button onClick={handleLogout} className="text-white hover:underline">
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
