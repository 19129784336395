import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDuc50RTZRs0ldZoEjIMnI1p-NfDHW4hPU",
  authDomain: "novely-bookshelf.firebaseapp.com",
  projectId: "novely-bookshelf",
  storageBucket: "novely-bookshelf.appspot.com",
  messagingSenderId: "152045458749",
  appId: "1:152045458749:web:46401e3c960ef73008e154",
  measurementId: "G-5GQTDCQ7D2"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
