// src/components/SignUp.js
import React, { useState, useContext, useEffect } from 'react';
import { auth } from '../firebase';
import { NOVELY_CORE_URL } from '../apiConfig'
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import Button from './Button';

function SignUp() {
  const { currentUser } = useContext(AuthContext);
  const { setUserId } = useContext(UserContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/user');
    }
  }, [currentUser, navigate]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      // Create a new user in Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const firebaseUser = userCredential.user;

      // Get the Firebase ID token
      const token = await firebaseUser.getIdToken();

      // Prepare the data to send to the backend
      const requestData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        firebase_user_id: firebaseUser.uid,
      };

      // Make the POST request to create the user in your backend
      const response = await axios.post(
        `${NOVELY_CORE_URL}/user`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Extract the userId from the response
      const { userId } = response.data;

      setUserId(userId);

      // Navigate to the User page
      navigate('/user');
    } catch (error) {
      console.error('Error signing up:', error);

      // Firebase Authentication Errors
      if (error.code && error.message) {
        alert(`Firebase Error: ${error.message}`);
      }
      // Axios Errors
      else if (error.response) {
        console.error('Server responded with an error:', error.response.data);
        alert(`Server Error: ${error.response.data.detail || 'An error occurred.'}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        alert('No response from the server. Please try again later.');
      } else {
        console.error('Error setting up the request:', error.message);
        alert('Error signing up. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 to-purple-200">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        {/* Logo or Brand Name */}
        <div className="text-center mb-6">
          <h1 className="text-4xl font-bold text-purple-600">Novely</h1>
        </div>

        {/* Sign-Up Form */}
        <form onSubmit={handleSignUp} className="space-y-6">
          <h2 className="text-2xl font-semibold text-gray-700 text-center">Sign Up</h2>

          {/* First Name Field */}
          <div>
            <label htmlFor="firstName" className="block text-gray-700 font-medium mb-2">
              First Name
            </label>
            <input
              id="firstName"
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>

          {/* Last Name Field */}
          <div>
            <label htmlFor="lastName" className="block text-gray-700 font-medium mb-2">
              Last Name
            </label>
            <input
              id="lastName"
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>

          {/* Email Field */}
          <div>
            <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
              Email
            </label>
            <input
              id="email"
              type="email"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          {/* Password Field */}
          <div>
            <label htmlFor="password" className="block text-gray-700 font-medium mb-2">
              Password
            </label>
            <input
              id="password"
              type="password"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength={6}
            />
          </div>

          {/* Sign-Up Button */}
          <div>
            <Button type="submit" className="w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 transition duration-200">
              Sign Up
            </Button>
          </div>

          {/* Login Link */}
          <div className="text-center">
            <p className="text-gray-600">
              Already have an account?{' '}
              <Link to="/login" className="text-purple-600 font-medium hover:underline">
                Login here
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
