// src/components/User.js
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import { auth } from '../firebase';
import { NOVELY_CORE_URL } from '../apiConfig'
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import { Link } from 'react-router-dom';
import Button from './Button';

function User() {
    const { userId } = useContext(UserContext);
    const [profiles, setProfiles] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfiles = async () => {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await axios.get(
                    `${NOVELY_CORE_URL}/profile?userId=${userId}`, // Adjust the endpoint as necessary
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setProfiles(response.data);
            } catch (error) {
                console.error('Error fetching profiles:', error);
            }
        };

        if (userId) {
            fetchProfiles();
        }
    }, [userId]);

    const handleCreateProfile = () => {
        navigate('/create-profile');
    };

    return (
        <div className="min-h-screen flex flex-col bg-gradient-to-br from-blue-100 to-purple-200">
            <NavBar />
            <main className="flex-grow">
                <div className="container mx-auto p-4">
                    {/* Brand Name */}
                    <div className="text-center mb-6">
                        <h1 className="text-4xl font-bold text-purple-600">Novely</h1>
                    </div>

                    <h2 className="text-3xl font-bold text-gray-700 text-center mb-6">
                        Your Profiles
                    </h2>

                    {/* Create Profile Button */}
                    <div className="flex justify-center mb-8">
                        <Button
                            onClick={handleCreateProfile}
                            className="bg-purple-600 text-white py-2 px-6 rounded-md hover:bg-purple-700 transition duration-200"
                        >
                            Create a New Profile
                        </Button>
                    </div>

                    {/* Profiles Section */}
                    {profiles.length === 0 ? (
                        <p className="text-center text-lg text-gray-700">
                            No profiles yet. Create one to get started!
                        </p>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {profiles.map((profile) => (
                                <Link
                                    key={profile.profileId}
                                    to={`/profile/${profile.profileId}`}
                                    className="block bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-200"
                                >
                                    <div className="p-6">
                                        <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                            {profile.profileName}
                                        </h3>
                                        <p className="text-gray-600">
                                            <span className="font-medium">Age:</span> {profile.profileAge}
                                        </p>
                                        <p className="text-gray-600">
                                            <span className="font-medium">Gender:</span> {profile.profileGender}
                                        </p>
                                        <p className="text-gray-600">
                                            <span className="font-medium">Likes:</span> {profile.profileLikes.join(', ')}
                                        </p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
}

export default User;
