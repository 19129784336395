// src/components/StoryForm.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import NavBar from './NavBar';
import Button from './Button';
import { auth } from '../firebase';
import { NOVELY_CORE_URL } from '../apiConfig'

function StoryForm() {
  const { userId } = useContext(UserContext);
  const { profileId } = useParams();
  const [premise, setPremise] = useState('');
  const [genres, setGenres] = useState([]);
  const [values, setValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Available genres and values
  const availableGenres = ['Adventure', 'Fantasy', 'Sci-Fi', 'Mystery', 'Drama', 'Comedy'];
  const availableValues = ['Friendship', 'Courage', 'Kindness', 'Honesty', 'Perseverance', 'Love'];

  const handleGenresChange = (e) => {
    const options = e.target.options;
    const selectedGenres = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedGenres.push(options[i].value);
      }
    }
    setGenres(selectedGenres);
  };

  const handleValuesChange = (e) => {
    const options = e.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setValues(selectedValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const token = await auth.currentUser.getIdToken();

      const requestData = {
        userId: userId,
        profileId: profileId,
        genres: genres,
        values: values,
        premise: premise,
      };

      // Step 1: Create a Story
      const storyResponse = await axios.post(
        `${NOVELY_CORE_URL}/story`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { storyId } = storyResponse.data;

      // Step 2: Create an Episode
      const episodeResponse = await axios.post(
        `${NOVELY_CORE_URL}/episode?storyId=${storyId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { episodeId } = episodeResponse.data;

      // Step 3: Create a Storyboard
      await axios.post(
        `${NOVELY_CORE_URL}/storyboard?episodeId=${episodeId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Step 4: Create a Video
      await axios.post(
        `${NOVELY_CORE_URL}/video?episodeId=${episodeId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Success
      alert('Your story and video are being processed!');
      navigate(`/profile/${profileId}`);
    } catch (error) {
      console.error('Error during submission:', error);

      if (error.response) {
        alert(`Error: ${error.response.data.detail || 'An error occurred.'}`);
      } else if (error.request) {
        alert('No response from the server. Please try again later.');
      } else {
        alert('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-blue-100 to-purple-200">
      <NavBar />
      <main className="flex-grow">
        <div className="container mx-auto p-4">
          {/* Brand Name */}
          <div className="text-center mb-6">
            <h1 className="text-4xl font-bold text-purple-600">Novely</h1>
          </div>

          <h2 className="text-3xl font-bold text-gray-700 text-center mb-6">
            Create Your Story
          </h2>

          <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-6">
            {/* Genres Selection */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Genres (select up to 3):
              </label>
              <select
                multiple
                value={genres}
                onChange={handleGenresChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                {availableGenres.map((genre) => (
                  <option key={genre} value={genre}>
                    {genre}
                  </option>
                ))}
              </select>
              <p className="text-sm text-gray-500 mt-1">
                Hold down the Ctrl (Windows) or Command (Mac) button to select multiple options.
              </p>
            </div>

            {/* Values Selection */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Values (select up to 3):
              </label>
              <select
                multiple
                value={values}
                onChange={handleValuesChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                {availableValues.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
              <p className="text-sm text-gray-500 mt-1">
                Hold down the Ctrl (Windows) or Command (Mac) button to select multiple options.
              </p>
            </div>

            {/* Premise Input */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Story Premise:
              </label>
              <textarea
                name="premise"
                value={premise}
                onChange={(e) => setPremise(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Enter a brief description of your story..."
                rows={4}
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <Button
                type="submit"
                disabled={isLoading}
                className={`w-full bg-purple-600 text-white py-2 px-4 rounded-md transition duration-200 ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700'
                  }`}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}

export default StoryForm;
