// src/components/ProfilePage.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth } from '../firebase';
import { NOVELY_CORE_URL } from '../apiConfig'
import NavBar from './NavBar';
import Button from './Button';

function ProfilePage() {
    const { profileId } = useParams(); // Get profileId from URL
    const [videos, setVideos] = useState([]);
    const [profileName, setProfileName] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = await auth.currentUser.getIdToken();

                // Fetch profile details
                const profileResponse = await axios.get(
                    `${NOVELY_CORE_URL}/profile/${profileId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setProfileName(profileResponse.data.profileName);

                // Fetch videos associated with the profile
                const videosResponse = await axios.get(
                    `${NOVELY_CORE_URL}/video?profileId=${profileId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setVideos(videosResponse.data);
            } catch (error) {
                console.error('Error fetching profile data:', error);
                // Handle errors (e.g., redirect if profile not found)
            }
        };

        fetchProfileData();
    }, [profileId]);

    // Handler to create a new story
    const handleCreateStory = () => {
        navigate(`/create-story/${profileId}`);
    };

    return (
        <div className="min-h-screen flex flex-col bg-gradient-to-br from-blue-100 to-purple-200">
            <NavBar />
            <main className="flex-grow">
                <div className="container mx-auto p-4">
                    {/* Profile Name */}
                    <div className="text-center mb-6">
                        <h1 className="text-4xl font-bold text-purple-600">Novely</h1>
                    </div>

                    <h2 className="text-3xl font-bold text-gray-700 text-center mb-6">
                        {profileName}'s Stories
                    </h2>

                    {/* Create Story Button */}
                    <div className="flex justify-center mb-8">
                        <Button
                            onClick={handleCreateStory}
                            className="bg-purple-600 text-white py-2 px-6 rounded-md hover:bg-purple-700 transition duration-200"
                        >
                            Create a New Story
                        </Button>
                    </div>

                    {/* Videos Section */}
                    {videos.length === 0 ? (
                        <p className="text-center text-lg text-gray-700">
                            No stories yet. Create one to get started!
                        </p>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {videos.map((video) => (
                                <div
                                    key={video.videoId}
                                    className="bg-white rounded-lg shadow-lg overflow-hidden"
                                >
                                    <div className="relative">
                                        <iframe
                                            src={`https://www.youtube.com/embed/${video.youtubeId}`}
                                            title={video.title}
                                            frameBorder="0"
                                            allowFullScreen
                                            className="w-full h-56"
                                        ></iframe>
                                    </div>
                                    <div className="p-4">
                                        <h3 className="text-xl font-semibold text-gray-800 mb-2">
                                            {video.title}
                                        </h3>
                                        <p className="text-gray-600 text-sm">
                                            {/* You can include additional video details here */}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
}

export default ProfilePage;
