// src/components/Login.js
import React, { useState, useContext, useEffect } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { NOVELY_CORE_URL } from '../apiConfig'
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import Button from './Button';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { currentUser } = useContext(AuthContext);
  const { setUserId } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/user');
    }
  }, [currentUser, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Sign in with Firebase
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const firebaseUser = userCredential.user;

      // Get the Firebase ID token
      const token = await firebaseUser.getIdToken();

      // Get the firebase_user_id
      const firebaseUserId = firebaseUser.uid;

      // Make a GET request to retrieve the user data from your backend
      const response = await axios.get(
        `${NOVELY_CORE_URL}/user/firebase/${firebaseUserId}`, // Use environment variable for API base URL
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Extract the userId from the response
      const { userId } = response.data;

      // Store the userId
      setUserId(userId);

      // Navigate to the User page
      navigate('/user');
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Invalid email or password. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 to-purple-200">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        {/* Logo or Brand Name */}
        <div className="text-center mb-6">
          <h1 className="text-4xl font-bold text-purple-600">Novely</h1>
        </div>

        {/* Login Form */}
        <form onSubmit={handleLogin} className="space-y-6">
          <h2 className="text-2xl font-semibold text-gray-700 text-center">Login</h2>

          {/* Email Field */}
          <div>
            <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
              Email
            </label>
            <input
              id="email"
              type="email"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          {/* Password Field */}
          <div>
            <label htmlFor="password" className="block text-gray-700 font-medium mb-2">
              Password
            </label>
            <input
              id="password"
              type="password"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          {/* Login Button */}
          <div>
            <Button type="submit" className="w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 transition duration-200">
              Login
            </Button>
          </div>

          {/* Register Link */}
          <div className="text-center">
            <p className="text-gray-600">
              Don't have an account?{' '}
              <Link to="/signup" className="text-purple-600 font-medium hover:underline">
                Register here
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
